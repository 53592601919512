import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { SectionWrapper, MotherWebsiteText } from 'styles/Typography.styled';

export const StyledSectionWrapper = styled(SectionWrapper)`
    display: flex;
    padding: 160px 0 0;

    ${mediaQueries.md} {
        align-items: center;
        padding: 56px 16px;
    }
    ${mediaQueries.sm} {
        flex-direction: column;
    }
`;

export const LeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    padding-right: 100px;

    ${mediaQueries.sm} {
        padding-right: 0;
    }
`;

export const RightWrapper = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
`;

export const TextDescription = styled(MotherWebsiteText)<{
    removeMarginTop?: boolean;
}>(
    ({ removeMarginTop, theme: { fontWeight, colors } }) =>
        css`
            color: ${colors.neutral300};
            display: flex;
            font-weight: ${fontWeight.medium};
            line-height: 32px;
            margin-top: ${removeMarginTop ? '0' : '24px'};
            padding-bottom: 40px;
        `,
);

export const Image = styled.img`
    flex: 1;
    max-height: 504px;
    max-width: 100%;

    ${mediaQueries.sm} {
        max-height: unset;
        display: none;
    }
`;

export const ImageMobile = styled.img`
    display: none;

    ${mediaQueries.sm} {
        display: block;
        max-width: 100%;
    }
`;
