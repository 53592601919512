import { mediaQueries } from 'shared/breakpoints';
import styled from 'styled-components';
import {
    PrimaryButton,
    SectionText,
    SectionWrapper,
} from 'styles/Typography.styled';

export const Wrapper = styled.div`
    display: grid;
    width: 100%;

    ${mediaQueries.sm} {
        place-items: center;
    }
`;

export const PageWrapper = styled(SectionWrapper)`
    padding: 80px 0 160px;

    ${mediaQueries.md} {
        padding: 24px 80px 70px;
    }

    ${mediaQueries.sm} {
        padding: 24px 24px 70px;
    }
`;

export const Info = styled(SectionText)`
    margin: 24px 0 48px 0;
    max-width: 500px;
`;

export const Button = styled(PrimaryButton)`
    width: 300px;

    ${mediaQueries.sm} {
        width: auto;
    }
`;
