import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import {
    SectionWrapper,
    MotherWebsiteText,
    H2,
} from 'styles/Typography.styled';

export const StyledSectionWrapper = styled(SectionWrapper)`
    display: flex;
    padding: 160px 0 0;
    margin-bottom: 72px;

    ${mediaQueries.md} {
        align-items: center;
        padding: 0px 16px;
    }
    ${mediaQueries.sm} {
        flex-direction: column;
    }
`;

export const LeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 100px;

    ${mediaQueries.sm} {
        padding-right: 0;
    }
`;

export const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const TextDescription = styled(MotherWebsiteText)(
    ({ theme: { fontWeight, colors } }) =>
        css`
            color: ${colors.neutral300};
            font-weight: ${fontWeight.medium};
            line-height: 32px;
            margin-top: 24px;
            margin-bottom: 72px;

            ${mediaQueries.sm} {
                margin-bottom: 40px;
            }
        `,
);

export const TextTitle = styled(H2)`
    margin-top: 72px;

    ${mediaQueries.sm} {
        margin-top: unset;
    }
`;

export const Image = styled.img`
    flex: 1;
    max-height: 504px;
    max-width: 100%;

    ${mediaQueries.sm} {
        max-height: unset;
        display: none;
    }
`;

export const ImageMobile = styled.img`
    display: none;

    ${mediaQueries.sm} {
        display: block;
        max-width: 100%;
        order: 1;
        margin-bottom: 40px;
    }
`;
